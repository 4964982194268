import { request } from '@/request/request'

export class UserRequest {
  static async getUserInfo(params) {
    const url = '/login'

    return await request.axios.post(url, params)
  }

  static async refreshToken(params) {
    const url = '/refresh'

    return await request.axios.post(url, params)
  }
}
