<template>
  <RhsHeaderApp>
    <template #right>
      <div class="header-buttons-wrapper">
        <div class="locale-changer">
          <select v-model="$i18n.locale">
            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
              {{ locale }}
            </option>
          </select>
        </div>
        <RhsButton
          :label="$t('main.batches')"
          :disabled="isOnBatchesPage"
          :class="{ 'is-on-batches-page': isOnBatchesPage }"
          class="button-black"
          variant="tertiary"
          @click="onClickActionButton('Home')"
        />
        <RhsButton
          :label="$t('common.search')"
          :class="{ 'is-on-batches-page': isOnSearchPage }"
          :disabled="isOnSearchPage"
          class="button-black"
          variant="tertiary"
          @click="onClickActionButton('BatchesSearch')"
        />
        <RhsButton
          :label="$t('common.logout')"
          class="button-red"
          variant="tertiary"
          @click="onClickActionButton('Login')"
        />
      </div>
    </template>
  </RhsHeaderApp>

  <main class="main-wrapper">
    <router-view />
  </main>
</template>

<script>
import { RhsButton, RhsFlyouts, RhsHeaderApp } from '@rhsolutions/design-system'
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAuthorizationStore } from '@/stores/views/authorization.store'

export default defineComponent({
  name: 'Layout',
  components: {
    RhsButton,
    RhsHeaderApp,
    RhsFlyouts,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const userStore = useAuthorizationStore()

    const onClickActionButton = (path) => {
      router.push({ name: path })

      if (path === 'Login') userStore.logout()
    }

    const isOnBatchesPage = computed(() => route.path === '/')
    const isOnSearchPage = computed(() => route.path === '/search')

    function getFlagEmoji(countryCode) {
      return [...countryCode.toUpperCase()]
        .map((char) => String.fromCodePoint(127397 + char.charCodeAt()))
        .reduce((a, b) => `${a}${b}`)
    }

    return { isOnBatchesPage, isOnSearchPage, onClickActionButton, getFlagEmoji }
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

.main-wrapper {
  background-color: $black-5;
  min-height: calc(100vh - 81px);
}

.header-buttons-wrapper {
  display: flex;
  gap: 34px;

  .locale-changer {
    display: flex;
    align-items: center;
  }

  .rhs-flyouts {
    display: flex;
    justify-content: center;
  }

  button {
    font-size: 14px;
    font-weight: 500;
  }

  .is-on-batches-page {
    opacity: 0.3;
  }

  .button-black {
    color: $black;

    &:hover {
      opacity: 0.7;
    }
  }

  .button-red {
    color: $active-red;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
