<template>
  <main>
    <router-view />
  </main>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoginLayout',
  components: {},
  setup() {
    return {}
  },
})
</script>
