import { defineStore } from 'pinia'
import VueJwtDecode from 'vue-jwt-decode'

import { request } from '@/request/request'
import { UserRequest } from '@/request/requests/user.request'

export const useAuthorizationStore = defineStore('authorization', {
  state: () => ({
    form: { login: '', password: '' },
    data: {},
    isLoading: false,
    isEnterButtonDisabled: false,
    errorMessage: '',
  }),
  actions: {
    async login() {
      this.isLoading = true
      this.isEnterButtonDisabled = false

      try {
        const res = await UserRequest.getUserInfo(this.form)

        localStorage.setItem(
          'tokens',
          JSON.stringify({
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          }),
        )

        request.setAuthToken(res.data.accessToken)
        this.data = VueJwtDecode.decode(res.data.accessToken)
      } catch (error) {
        this.isEnterButtonDisabled = true
      } finally {
        this.isLoading = false
      }
    },
    async refreshToken(tokenForm) {
      this.isLoading = true

      try {
        const res = await UserRequest.refreshToken(tokenForm)

        localStorage.setItem(
          'tokens',
          JSON.stringify({
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          }),
        )

        request.setAuthToken(res.data.accessToken)
        this.data = VueJwtDecode.decode(res.data.accessToken)
      } catch (error) {
        this.isEnterButtonDisabled = true
      } finally {
        this.isLoading = false
      }
    },
    async getUser() {
      const storedTokens = localStorage.getItem('tokens')

      if (storedTokens) {
        const { accessToken } = JSON.parse(storedTokens)

        request.setAuthToken(accessToken)

        try {
          const res = await UserRequest.getUserInfo(this.form)
          if (res?.data) this.data = VueJwtDecode.decode(res.data.accessToken)
        } catch (error) {
          console.error('Error fetching user data', error)
        }
      }
    },
    logout() {
      this.form.login = ''
      this.form.password = ''
      this.data = {}
      localStorage.removeItem('tokens')
    },
  },
  getters: {},
})
