import { createI18n } from 'vue-i18n'

import chCommon from '@/locales/ch/common.json'
import chMain from '@/locales/ch/main.json'
import { request } from '@/request/request'

import enCommon from '../locales/en/common.json'
import enMain from '../locales/en/main.json'
import ruCommon from '../locales/ru/common.json'
import ruMain from '../locales/ru/main.json'

const messages = {
  en: {
    common: enCommon,
    main: enMain,
  },
  ru: {
    common: ruCommon,
    main: ruMain,
  },
  ch: {
    common: chCommon,
    main: chMain,
  },
}

const storedLocale = localStorage.getItem('locale')
const browserLocale = navigator.language.split('-')[0]
const initialLocale = storedLocale || (browserLocale in messages ? browserLocale : 'ru')

const i18n = createI18n({
  legacy: false,
  locale: initialLocale,
  fallbackLocale: 'ru',
  messages,
})

request.setAcceptLanguage(initialLocale)

export default i18n
