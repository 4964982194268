import axios from 'axios'
import qs from 'qs'

import { AppConfig } from '@/config/app.config'
import router from '@/router'
import { useLayoutStore } from '@/stores/modules/layout.store'

class Request {
  axiosInstance

  constructor() {
    this.init()
  }

  init() {
    this.axiosInstance = axios.create({
      baseURL: AppConfig.backUrl,
      timeout: 300000,
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: true, arrayFormat: 'repeat' })
      },
    })

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        return this.errorHandler(error)
      },
    )
  }

  setAuthToken(token) {
    this.axiosInstance.defaults.headers.authorization = `Bearer ${token}`
  }

  setAcceptLanguage(language) {
    this.axiosInstance.defaults.headers['Accept-Language'] = language
  }

  get axios() {
    return this.axiosInstance
  }

  async errorHandler(error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('tokens')
      await router.push({ name: 'Login' })
    } else if (error.response && error.response.status >= 422) {
      useLayoutStore().setError(error.response.status, error.response.data.title, error.response.data.message)
      useLayoutStore().errorModal = true
    } else {
      await router.push({ name: 'Error' })
    }
  }
}

export const request = new Request()
