import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    error: {
      code: null,
      title: null,
      message: null,
    },
    errorModal: false,
  }),
  actions: {
    setError(code = null, title = null, message = null) {
      this.error.code = code
      this.error.title = title
      this.error.message = message
    },
  },
})
